import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "element-matches-polyfill"
import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import store from "./store"
import App from "./App"

// Note: Order of these changes which CSS gets priority during conflicts. The 2nd one will override the 1st one in the event of a conflict.
import "./assets/css/tailwind.min.css"
import "./assets/scss/global.scss"

render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
)
