import GenericService from "./generic"

const SERVICE_NAME = "profile"

class ProfileService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios({ refreshTokenOnUnauthorized: false })
	}

	async fetchProfile() {
		try {
			return await this.axios.get("/my-account/customer/profile")
		} catch (e) {
			// If we get an error that is not related to profile not found (404) then we must assume something is wrong
			// And for that, throw the error so the consumer of this function can react accordingly
			if (e.isAxiosError && e.response && e.response.status === 404) {
				return null
			} else {
				throw e
			}
		}
	}
}

export default new ProfileService()
