import ConfigService from "./services/config"
import Cookies from "universal-cookie"
import DOMPurify from "dompurify"
import moment from "moment"

export const SITE_NAME_AHS = "ahs"
export const SITE_NAME_HSA = "hsa"

export const PAYMENT_METHODS = {
	CREDIT_CARD: "CREDIT_CARD",
	BANK_ACCOUNT: "BANK_ACCOUNT",
	PAYPAL: "PAYPAL",
}

export const PAYMENT_METHODS_MAP = {
	CREDIT_CARD: "CARD",
	BANK_ACCOUNT: "ACH",
	PAYPAL: "PAYPAL",
}

export const PROMOTION_TYPE = {
	AMOUNT: "amount",
	PERCENT: "percent",
}

export const getSiteName = () => ConfigService.config?.site?.name

export const isSiteAHS = () => getSiteName() === SITE_NAME_AHS
export const isSiteHSA = () => getSiteName() === SITE_NAME_HSA

//for the request service confirmation modals
export const STREEM_POPUP_VIDEO = "VIDEO_DIAGNOSIS"
export const STREEM_POPUP_CALL = "CLICK_TO_CALL"

export const pageTitle = (title) => {
	return title + (ConfigService.config.site.brandLong ? ` | ${ConfigService.config.site.brandLong}` : "")
}

export const setPageTitle = (title, useFormatter = true) => {
	document.title = useFormatter ? pageTitle(title) : title
}

export const setTheme = () => {
	document.documentElement.classList.add(`theme-${getSiteName()}`)
}

export const isObject = (obj) => Object.prototype.toString.call(obj) === "[object Object]"

export const extend = (target, ...sources) => {
	if (!isObject(target)) {
		target = {}
	}

	sources.forEach((source) => {
		if (isObject(source)) {
			Object.keys(source).forEach((key) => {
				if (key in target && isObject(target[key]) && isObject(source[key])) {
					extend(target[key], source[key])
				} else if (isObject(source[key])) {
					target[key] = extend({}, source[key])
				} else {
					target[key] = source[key]
				}
			})
		}
	})

	return target
}

export const applyTranslations = (content, translationsByLanguage, language) => {
	if (!content || !translationsByLanguage || !language || !(language in translationsByLanguage)) {
		return content
	}

	const translations = translationsByLanguage[language]

	return String(content).replace(/\{i18n:([^}]+)\}/g, (match, key) => {
		if (translations[key]) {
			return translations[key]
		} else {
			return match
		}
	})
}

export const makeCancelable = (promise) => {
	let canceled = false

	const wrappedPromise = new Promise((resolve, reject) => {
		promise
			.then((value) => {
				if (!canceled) {
					resolve(value)
				} else {
					const error = new Error(`Promise has been canceled.`)
					error.canceled = true
					reject(error)
				}
			})
			.catch((error) => {
				if (!canceled) {
					reject(error)
				} else {
					const error = new Error(`Promise has been canceled.`)
					error.canceled = true
					reject(error)
				}
			})
	})

	return {
		promise: wrappedPromise,
		cancel: () => {
			canceled = true
		},
	}
}

export const getPhoneNumberDigits = (phoneNumber) => (typeof phoneNumber === "string" && phoneNumber.replace(/[^\d]/g, "").length) || 0

export const isValidPhoneNumber = (phoneNumber) => getPhoneNumberDigits(phoneNumber) === 10

export const arePhoneNumbersEqual = (a, b) =>
	typeof a === "string" && typeof b === "string" && a.replace(/[^\d]/g, "") === b.replace(/[^\d]/g, "")

export const isValidEmailAddress = (email) => /^[^@]+@[^.]+\..+$/.test(email)

export const capitalizeWords = (str) => str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())

export const isMobile = () => window.innerWidth < 768

export const isDesktop = () => !isMobile()

export const setLoginRedirect = (url) => {
	// default to current URL
	url = url ?? window.location.href

	// replace domain in URL if it matches current domain
	url = url.replace(window.location.origin, "")

	window.sessionStorage.setItem("loginRedirect", url)
}

export const divideArray = (array, numPieces) => {
	const maxPieceSize = Math.ceil(array.length / numPieces)

	return Array.from(Array(numPieces)).map((_, i) => array.slice(i * maxPieceSize, (i + 1) * maxPieceSize))
}

export const getPaymentMethodText = (paymentMethod, localizedText) => {
	switch (paymentMethod.type) {
		case PAYMENT_METHODS.CREDIT_CARD:
		case PAYMENT_METHODS_MAP.CREDIT_CARD: {
			return localizedText("PAYMENT_METHODS.DISPLAY_TEXT_CARD", {
				cardType: paymentMethod.cardType,
				cardNumberLastFour: paymentMethod.cardNumber ?? paymentMethod.cardNumberLastFour,
			})
		}
		case PAYMENT_METHODS.BANK_ACCOUNT:
		case PAYMENT_METHODS_MAP.BANK_ACCOUNT: {
			return localizedText("PAYMENT_METHODS.DISPLAY_TEXT_ACH", {
				accountNumberLastFour: paymentMethod.accountNumber?.slice(-4) ?? paymentMethod.accountNumberLastFour,
			})
		}
		case PAYMENT_METHODS_MAP.PAYPAL: {
			return localizedText("PAYMENT_METHODS.DISPLAY_TEXT_PAYPAL", {
				email: paymentMethod.email,
			})
		}
		default: {
			return "UNKNOWN PAYMENT METHOD TYPE"
		}
	}
}

/**
 * Find customerID given a contractID
 * @param {*[]} contractCustomer
 * @param {string} contractID
 * @return {string}
 */
export const getContractCustomerID = (contractCustomer, contractID) => {
	return contractCustomer?.find((item) => item.contractID === contractID)?.customerID
}

export const getUrlByType = (type, url, map) => {
	const domain = (map && map[type]) || ""
	return `${domain}${url}`
}

export const stringifyError = (error) => {
	if (!error) {
		return "NO_ERROR"
	} else if (typeof error === "string") {
		return error
	} else if (error instanceof Error) {
		return error.toString()
	} else if (error.isAxiosError) {
		const endpoint = `${error.config.method} ${error.config.baseURL}${error.config.url}`
		if (error.response && error.response.statusText) {
			return `Axios Response for ${endpoint}: HTTP ${error.response.status}: ${error.response.statusText}`
		} else if (error.response) {
			return `Axios Response for ${endpoint}: ${getAxiosErrorMessage(error)}`
		} else {
			return `Axios No Response for ${endpoint}`
		}
	} else {
		return JSON.stringify(error)
	}
}

export const getAxiosErrorMessage = (axiosError) => {
	const errors = axiosError?.response?.data?.errors
	return (errors || [{ message: axiosError.message }]).map((error) => error.message).join(", ")
}

export const getFormattedPrice = (price, minDecimal = 2, maxDecimal = 2, locale = "en-US") =>
	price?.toLocaleString(locale, {
		maximumFractionDigits: maxDecimal > minDecimal ? maxDecimal : minDecimal,
		minimumFractionDigits: minDecimal,
	})

export const SetMyAccountCookie = (isLoggedIn) => {
	//cookie should expire after 6 months.
	const cookies = new Cookies()
	const inSixMonths = new Date()
	inSixMonths.setMonth(inSixMonths.getMonth() + 6)
	cookies.set("ue", "ma", { path: "/", expires: inSixMonths, domain: ".ahs.com" })
	if (isLoggedIn) {
		cookies.set("existingCustomer", "true", { path: "/", expires: inSixMonths, domain: ".ahs.com" })
	}
}

export const getSelectedAndRemovedAddons = (oldAddons, newAddons) => {
	return {
		selectedAddons: newAddons
			.filter(
				(c1) =>
					oldAddons.some((c2) => c1.coverageID === c2.coverageID && c1.quantity > c2.quantity) ||
					(!oldAddons.includes(c1) && c1.quantity > 0)
			)
			.map((a) => a.name),
		removedAddons: oldAddons
			.filter((c1) => newAddons.some((c2) => c2.coverageID === c1.coverageID && c2.quantity < c1.quantity))
			.map((a) => a.name),
	}
}

export const getAddedAndRemovedAddons = (oldAddons, newAddons) => {
	const oldAddonIds = oldAddons.filter((x) => x.quantity > 0).map((cov) => cov.coverageID)
	const NewAddonsIds = newAddons.filter((x) => x.quantity > 0).map((cov) => cov.coverageID)

	// Set the added coverages
	let addedCoverages = []
	NewAddonsIds.forEach((newCoverageId) => {
		if (!oldAddonIds.includes(newCoverageId)) {
			addedCoverages.push(newAddons.find((coverage) => coverage.coverageID === newCoverageId).name)
		}
	})

	// Set the removed coverages
	let removedCoverages = []
	oldAddonIds.forEach((existingCoverageId) => {
		if (!NewAddonsIds.includes(existingCoverageId)) {
			removedCoverages.push(oldAddons.find((coverage) => coverage.coverageID === existingCoverageId).name)
		}
	})

	return {
		addedAddons: addedCoverages,
		removedAddons: removedCoverages,
	}
}

export const getGroupAddedAndRemovedAddons = (oldGroups, newGroups) => {
	const oldGroupIds = oldGroups?.filter((x) => x.selected).map((cov) => cov.coverageID)
	const newGrouponsIds = newGroups?.filter((x) => x.selected).map((cov) => cov.coverageID)

	// Set the added group coverages
	let addedGroupCoverages = []
	newGrouponsIds?.forEach((newCoverageId) => {
		if (!oldGroupIds?.includes(newCoverageId)) {
			addedGroupCoverages?.push(newGroups?.find((coverage) => coverage.coverageID === newCoverageId).description)
		}
	})

	// Set the removed group coverages
	let removedGroupCoverages = []
	oldGroupIds?.forEach((existingCoverageId) => {
		if (!newGrouponsIds?.includes(existingCoverageId)) {
			removedGroupCoverages?.push(oldGroups?.find((coverage) => coverage.coverageID === existingCoverageId).description)
		}
	})

	return {
		addedGroups: addedGroupCoverages,
		removedGroups: removedGroupCoverages,
	}
}

export const generateChangeNote = (contractId, originalProduct, selectedProduct) => {
	let { changeNote, addOnChange } = generateChangeNoteHelper(contractId, selectedProduct, originalProduct)
	return (changeNote += addOnChange)
}

export const generateChangeNoteNonEverGreen = (contractId, originalProduct, selectedProduct) => {
	let { changeNote, planChangeOrTsfChange, addOnChange } = generateChangeNoteNonEverGreenHelper(
		contractId,
		selectedProduct,
		originalProduct
	)
	return addOnChange || planChangeOrTsfChange ? changeNote + planChangeOrTsfChange + addOnChange : ""
}

function generateChangeNoteHelper(contractId, selectedProduct, originalProduct) {
	let changeNote = `Renewal contract ${contractId} coverage changed. `
	if (selectedProduct.planName !== originalProduct.planName) {
		changeNote += `Plan changed from ${originalProduct.planName} to ${selectedProduct.planName}. `
	}
	if (selectedProduct.serviceFee !== originalProduct.serviceFee) {
		changeNote += `TSCF changed from ${originalProduct.serviceFee} to ${selectedProduct.serviceFee}. `
	}
	let addOnChange = ""
	const oldAddons = originalProduct.optionalCoverages
	const NewAddons = selectedProduct.optionalCoverages
	const { selectedAddons, removedAddons } = getSelectedAndRemovedAddons(oldAddons, NewAddons)
	if (selectedAddons.length) {
		addOnChange = `Added ${selectedAddons.join(", ")}. `
	}
	if (removedAddons.length) {
		addOnChange += addOnChange.length ? " and " : ""
		addOnChange += `Removed ${removedAddons.join(", ")}. `
	}
	return { changeNote, addOnChange }
}

function generateChangeNoteNonEverGreenHelper(contractId, selectedProduct, originalProduct) {
	let changeNote = `Renewal contract ${contractId} coverage changed. `
	let planChangeOrTsfChange = ""
	if (selectedProduct.planName !== originalProduct.planName) {
		planChangeOrTsfChange += `Plan changed from ${originalProduct.planName} to ${selectedProduct.planName}. `
	}
	if (selectedProduct.serviceFee !== originalProduct.serviceFee) {
		planChangeOrTsfChange += `TSCF changed from ${originalProduct.serviceFee} to ${selectedProduct.serviceFee}. `
	}
	let addOnChange = ""
	const oldAddons = originalProduct.optionalCoverages
	const NewAddons = selectedProduct.optionalCoverages

	const { addedGroups, removedGroups } = getGroupAddedAndRemovedAddons(originalProduct.groupCoverages, selectedProduct.groupCoverages)
	const { addedAddons, removedAddons } = getAddedAndRemovedAddons(oldAddons, NewAddons)

	const addedArray = addedAddons.concat(addedGroups)
	if (addedArray?.length) {
		addOnChange = `Added ${addedArray.join(", ")}. `
	}
	const removedArray = removedAddons.concat(removedGroups)
	if (removedArray?.length) {
		addOnChange += `Removed ${removedArray.join(", ")}. `
	}
	return { changeNote, planChangeOrTsfChange, addOnChange }
}
export const getFullPropertyAddress = (property) =>
	`${property.streetAddress},${property.streetAddress2 ? ` ${property.streetAddress2},` : ""} ${property.city}, ${property.state} ${
		property.zip
	}`

export const sanitizeHTML = (html) => DOMPurify.sanitize(html)

export const getTotalPromotionDiscount = (promotions) => promotions?.map((promo) => promo.amount).reduce((acc, current) => acc + current)

export const getPromotionsWithAmount = (product) =>
	product.promotions?.map((promo) => {
		return promo.discountType === PROMOTION_TYPE.PERCENT
			? { ...promo, amount: +getFormattedPrice(product.priceWithoutPromotions?.total * (promo.discount / 100)) }
			: { ...promo, amount: promo.discount }
	})

export const getDateWithOffset = (date) => moment(date).utcOffset("-05:00")

export const getCoveragePeriod = (expirationDate) => {
	let coverageStartDate = expirationDate

	if (moment().diff(getDateWithOffset(coverageStartDate), "days") > 45) {
		coverageStartDate = moment.now()
	}

	return getDateWithOffset(coverageStartDate).format("l") + " - " + getDateWithOffset(coverageStartDate).add(1, "year").format("l")
}

export const calculateDaysPastExpiration = (expirationDate) => Math.floor(moment().diff(getDateWithOffset(expirationDate), "days", true))

export const translationExists = (key, localizedText) => localizedText(key) !== key
