import React from "react"

import Header from "./Header/Header"
import PageContent from "./PageContent"
import Footer from "./Footer/Footer"

export class Page extends React.Component {
	render() {
		return (
			<>
				<Header />
				<PageContent className={this.props.className}>{this.props.children}</PageContent>
				{!this.props.removeFooter && <Footer className={this.props.footerClassName} />}
			</>
		)
	}
}

export default Page
