import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import ContactService from "../../services/contact"
import { makeCancelable } from "../../utils"

const actionsBuilder = new ActionsBuilder(namespace)

export const fetchEfulfillmentPreferenceStart = actionsBuilder.createAction("fetchEfulfillmentPreferenceStart", (state, loadingPromise) => {
	state[namespace].preferenceLoading = true
	state[namespace].preferenceLoadingPromise = loadingPromise

	state[namespace].preferenceFetchError = false
})

export const fetchEfulfillmentPreferenceEnd = actionsBuilder.createAction(
	"fetchEfulfillmentPreferenceEnd",
	(state, { eFulfillmentEnabled, eFulfillmentAccessible }) => {
		if (eFulfillmentEnabled !== undefined) {
			state[namespace].eFulfillmentEnabled = eFulfillmentEnabled
			// In case of undefined, set accessible to false
			state[namespace].eFulfillmentAccessible = Boolean(eFulfillmentAccessible)
		} else {
			state[namespace].preferenceFetchError = true
		}

		state[namespace].preferenceLoading = false
		state[namespace].preferenceLoadingPromise = null
	}
)

export const savingEfulfillmentPreferenceStart = actionsBuilder.createAction("savingEfulfillmentPreferenceStart", (state) => {
	state[namespace].preferenceSaving = true
})

export const savingEfulfillmentPreferenceEnd = actionsBuilder.createAction(
	"savingEfulfillmentPreferenceEnd",
	(state, eFulfillmentEnabled) => {
		if (eFulfillmentEnabled !== undefined) {
			state[namespace].eFulfillmentEnabled = eFulfillmentEnabled
		}

		state[namespace].preferenceSaving = false
	}
)

export const ContactActions = {
	fetchEfulfillmentPreference: () => async (dispatch, getState) => {
		const state = getState()
		const { preferenceLoadingPromise } = state[namespace]
		const contractID = state.contract.current.contractID

		if (preferenceLoadingPromise) {
			preferenceLoadingPromise.cancel()
		}

		const cancelablePromise = makeCancelable(ContactService.fetchEfulfillmentPreference(contractID))
		dispatch(fetchEfulfillmentPreferenceStart(cancelablePromise))

		try {
			const response = await cancelablePromise.promise

			dispatch(fetchEfulfillmentPreferenceEnd(response))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchEfulfillmentPreferenceEnd({}))
			}
		}
	},
	saveEfulfillmentPreference: (eFulfillmentEnabled) => async (dispatch, getState) => {
		const state = getState()
		const { preferenceSaving } = state[namespace]
		const contractID = state.contract.current.contractID

		if (preferenceSaving) {
			throw new Error("Cannot save contact preferences before previous call has completed")
		}

		dispatch(savingEfulfillmentPreferenceStart())
		try {
			await ContactService.saveEfulfillmentPreference(contractID, eFulfillmentEnabled)

			dispatch(savingEfulfillmentPreferenceEnd(eFulfillmentEnabled))
		} catch (e) {
			dispatch(savingEfulfillmentPreferenceEnd())
			throw e
		}
	},
}

export const actions = actionsBuilder.exportActions()
