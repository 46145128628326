import ConfigService from "./config"
import axios from "axios"
import jwtDecode from "jwt-decode"
import Cookies from "universal-cookie"
import { GetGuestToken } from "@ftdr/crypto-js"
import moment from "moment"

const GUEST_TOKEN_RETRY_ATTEMPTS = 3

class AuthService {
	getClientConfig() {
		const siteHostname = window.location.origin
		const config = ConfigService.config?.auth ?? {}

		return {
			authority: config.host,
			metadata_uri: `${config.host}/.well-known/openid-configuration/${config.tenant_id}`,
			client_id: config.client_id,
			redirect_uri: `${siteHostname}/login`,
			post_logout_redirect_uri: `${siteHostname}/logout`,
			scope: "openid email profile",
			debug: config.debug ?? false,
			extraQueryParams: {
				tenantId: config.tenant_id,
			},
		}
	}

	getAxios() {
		return axios.create({
			baseURL: ConfigService.config?.auth?.host,
		})
	}

	async getUserInfo() {
		const accessToken = this.getAccessTokenFromCookie()
		const response = await this.getAxios().get("/api/user", {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})

		return response.data.user
	}

	getGuestToken(attemptsRemaining = 0) {
		return new Promise((resolve, reject) => {
			GetGuestToken({
				baseUrl: ConfigService.config?.auth?.guestHost,
				handler: (token) => {
					if (token instanceof Error) {
						if (attemptsRemaining > 0) {
							this.getGuestToken(attemptsRemaining - 1)
								.then(resolve)
								.catch(reject)
						} else {
							reject(token)
						}
					} else {
						resolve(token)
					}
				},
			})
		})
	}

	async getGuestTokenWithSession() {
		let guestToken = window.sessionStorage.getItem("guestToken")

		if (guestToken && moment.unix(jwtDecode(guestToken).exp).isAfter(moment.now())) {
			return guestToken
		}

		guestToken = await this.getGuestToken(GUEST_TOKEN_RETRY_ATTEMPTS)

		window.sessionStorage.setItem("guestToken", guestToken)

		return guestToken
	}

	async getAccessToken() {
		const accessToken = this.getAccessTokenFromCookie()

		if (accessToken) {
			return { token: accessToken, tokenType: "Bearer", isGuestToken: false }
		}

		const guestToken = await this.getGuestTokenWithSession()

		return { token: guestToken, tokenType: "Bearer", isGuestToken: true }
	}

	getAccessTokenFromCookie() {
		const cookies = new Cookies()
		//get the FusionAuth access token from the cookie.
		const accessTokenFromCookie = cookies.get("accessToken")
		return accessTokenFromCookie
	}
}

export default new AuthService()
