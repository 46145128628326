import ConfigService from "../services/config"

export default class QuiqChat {
	static scriptAdded = false
	static loading = false
	static hasInitialized = false
	static contactPoints = null
	static chatClient = null
	static queuedRequests = []

	static async initialize({ contactPoint, styles }) {
		if (window.Quiq === undefined || this.loading || contactPoint === "") {
			return null
		}
		this.loading = true
		if (this.chatClient === null) {
			this.chatClient = window.Quiq({
				contactPoint: contactPoint,
				styles: styles,
			})
		}
		this.loading = false
		this.hasInitialized = true
		return this.chatClient
	}

	static async reinitialize({ contactPoint, styles }) {
		if (this.chatClient === null) {
			throw new Error("Chat client is not initialized")
		}

		if (this.loading) {
			return new Promise((resolve, reject) => {
				this.queuedRequests.push({
					request: { contactPoint, styles },
					resolve,
					reject,
				})
			})
		}

		this.loading = true

		try {
			await this.chatClient.reinitialize({ contactPoint, styles })
		} catch (e) {
			this.loading = false
			if (!this.queuedRequests.length) {
				throw e
			}
		}

		this.loading = false

		if (this.queuedRequests.length) {
			// get the most recent request to process
			const { request, resolve, reject } = this.queuedRequests.pop()

			// remove the rest of the pending requests and tell them they have been rejected
			this.queuedRequests.splice(0, this.queuedRequests.length).forEach(({ reject }) => reject())

			// process the most recent request
			this.reinitialize(request).then(resolve).catch(reject)
		}

		return this.chatClient
	}

	static loadConfig() {
		const config = ConfigService.config.quiqChat

		this.contactPoints = config.contactPoints
	}

	static addScript() {
		if (this.scriptAdded) return

		const script = document.createElement("script")
		script.type = "text/javascript"
		script.src = ("https:" === document.location.protocol ? "https://" : "http://") + "frontdoor.quiq-api.com/app/webchat/index.js"

		document.head.appendChild(script)
		this.scriptAdded = true
	}

	static async unload(clearSession) {
		if (this.chatClient !== null) {
			return await this.chatClient.unload(clearSession)
		}
		return false
	}
}
