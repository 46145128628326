import GenericService from "./generic"

const SERVICE_NAME = "zesty"

class ZestyService extends GenericService {
	constructor() {
		super(SERVICE_NAME)
	}

	_getJSON(contentPath, config = {}) {
		return this.axios.get(contentPath, { responseType: "json", ...config })
	}

	getTranslations() {
		return this._getJSON(`/translations.json`)
	}

	getLongDescription(coverageItemId) {
		return this._getJSON("/coverages_long_description.json", {
			params: { id: coverageItemId },
		}).then((res) => res.data)
	}

	getSampleContractLink(state) {
		return this._getJSON("/sample_contract_pdf.json", {
			params: { state: state },
		}).then((res) => res.data.link)
	}

	getRenewalConfig() {
		return this._getJSON("/renewals_global_settings.json").then((res) => res.data)
	}

	getCoverageDetails(uuid) {
		return this._getJSON("/coverage_modal.json", {
			params: { uuid: uuid },
		}).then((res) => res.data)
	}

	getPlanComparator(plans) {
		return this._getJSON("/coverage_comparison.json", {
			params: { plans: plans },
		}).then((res) => res.data)
	}
}

export default new ZestyService()
