import GenericService from "./generic"
import { capitalizeWords } from "../utils"

const SERVICE_NAME = "payment"

class PaymentService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
	}

	async fetchDefaultBillingAddress(contractID) {
		const response = await this.axios.get(`/my-account-renewals/${contractID}/billing-address`)

		const defaultBillingAddress = {
			...response,
			address1: capitalizeWords(response.streetAddress),
			address2: capitalizeWords(response.streetAddress2),
			city: capitalizeWords(response.city),
		}

		return defaultBillingAddress
	}
}

export default new PaymentService()
