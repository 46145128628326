import GenericService from "./generic"

const SERVICE_NAME = "contact"

class ContactService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
	}

	fetchEfulfillmentPreference(contractID) {
		return this.axios.get(`/my-account-renewals/efulfillment/${contractID}`)
	}

	saveEfulfillmentPreference(contractID, eFulfillmentEnabled) {
		return this.axios.put(`/my-account-renewals/efulfillment/${contractID}/enabled/${eFulfillmentEnabled}`)
	}
}

export default new ContactService()
