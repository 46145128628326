import { ActionsBuilder } from "../utils"
import namespace from "./namespace"

const actionsBuilder = new ActionsBuilder(namespace)

export const addAlert = actionsBuilder.createAction("addAlert", (state, { alerts, status, persist }) => {
	state[namespace].alerts = alerts.map((w) => ({ ...w, key: "RENEWALS_ERROR." + w.key, persist: persist, status: status }))
})

export const resetAlerts = actionsBuilder.createAction("resetAlerts", (state) => {
	state[namespace].alerts = []
})

export const AlertActions = {
	createAlert:
		(alerts, status, persist = false) =>
		(dispatch) => {
			if (alerts instanceof Array) {
				alerts.forEach((_) => {
					dispatch(addAlert({ alerts, status, persist }))
				})
			}
		},
	resetAlerts: () => (dispatch) => dispatch(resetAlerts()),
}

export const actions = actionsBuilder.exportActions()
