import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import PaymentService from "../../services/payment"
import { makeCancelable } from "../../utils"
import contractNamespace from "../contract/namespace"

const actionsBuilder = new ActionsBuilder(namespace)

export const fetchPaymentInfoEnd = actionsBuilder.createAction("fetchPaymentInfoEnd", (state, paymentInfo) => {
	if (paymentInfo) {
		state[namespace].paymentInfo = paymentInfo

		if (paymentInfo.pastDueInvoices) {
			paymentInfo.pastDueInvoices.forEach((pastDueInvoice) => {
				pastDueInvoice.payAmount = pastDueInvoice.amount
			})
		}
	}

	state[namespace].paymentInfoError = !paymentInfo

	state[namespace].paymentInfoLoading = false
	state[namespace].paymentInfoLoadingPromise = null
})

export const fetchDefaultBillingAddressStart = actionsBuilder.createAction("fetchDefaultBillingAddressStart", (state, loadingPromise) => {
	state[namespace].defaultBillingAddressLoading = true
	state[namespace].defaultBillingAddressLoadingPromise = loadingPromise

	state[namespace].defaultBillingAddressError = false
})

export const fetchDefaultBillingAddressEnd = actionsBuilder.createAction(
	"fetchDefaultBillingAddressEnd",
	(state, defaultBillingAddress) => {
		if (defaultBillingAddress) {
			state[namespace].defaultBillingAddress = defaultBillingAddress
		}

		state[namespace].defaultBillingAddressError = !defaultBillingAddress

		state[namespace].defaultBillingAddressLoading = false
		state[namespace].defaultBillingAddressLoadingPromise = null
	}
)

export const PaymentActions = {
	setPaymentInfoFromCurrentContract: () => (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]

		if (!current) {
			return
		}

		dispatch(fetchPaymentInfoEnd(current.paymentInfo))
	},
	fetchDefaultBillingAddress: () => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]
		const { defaultBillingAddressLoadingPromise } = state[namespace]

		if (defaultBillingAddressLoadingPromise) {
			defaultBillingAddressLoadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const cancelablePromise = makeCancelable(PaymentService.fetchDefaultBillingAddress(current.contractID))
		dispatch(fetchDefaultBillingAddressStart(cancelablePromise))

		try {
			const defaultBillingAddress = await cancelablePromise.promise

			dispatch(fetchDefaultBillingAddressEnd(defaultBillingAddress))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchDefaultBillingAddressEnd())
			}
		}
	},
}

export const actions = actionsBuilder.exportActions()
