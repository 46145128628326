import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import QuiqChatLibrary from "../../lib/quiqchat"

const actionsBuilder = new ActionsBuilder(namespace)

export const setNewContactPoint = actionsBuilder.createAction("setNewContactPoint", (state, contactPoint) => {
	state[namespace].currentContactPoint = contactPoint
})

export const QuiqChatActions = {
	loadChat:
		(contactPoint, styles = {}) =>
		async (dispatch, getState) => {
			const state = getState()
			const { currentContactPoint } = state[namespace]
			if (!QuiqChatLibrary.loading) {
				let chatClient = null
				try {
					if (currentContactPoint === "") {
						chatClient = await QuiqChatLibrary.initialize({ contactPoint, styles })
					} else if (currentContactPoint !== contactPoint) {
						chatClient = await QuiqChatLibrary.reinitialize({ contactPoint, styles })
					}
				} catch (e) {
					//TODO handle this better
					console.warn(e)
				}

				if (chatClient !== null) {
					dispatch(setNewContactPoint(contactPoint))
				}
			}
		},
}

export const actions = actionsBuilder.exportActions()
