export default {
	loading: false,
	fetchError: false,
	list: [],
	current: null,
	myPropertyInfoLoadingPromise: null,
	myPropertyInfoLoading: false,
	myPropertyInfoError: null,
	myPropertyInfo: null,
	isDownloadingContract: false,
}
