import namespace from "./namespace"
import { ActionsBuilder } from "../utils"

const actionsBuilder = new ActionsBuilder(namespace)

export const addVariation = actionsBuilder.createAction("addVariation", (state, { name, value }) => {
	state[namespace].variations[name] = value
})

export const addFeatureFlag = actionsBuilder.createAction("addFeatureFlag", (state, { name, value }) => {
	state[namespace].featureFlags[name] = value
})

export const resetVariations = actionsBuilder.createAction("resetVariations", (state) => {
	state[namespace].variations = {}
})

export const resetFeatureFlags = actionsBuilder.createAction("resetFeatureFlags", (state) => {
	state[namespace].featureFlags = {}
})

export const OptimizelyActions = {
	addVariation: (name, value) => (dispatch) => dispatch(addVariation({ name, value })),
	addFeatureFlag: (name, value) => (dispatch) => dispatch(addFeatureFlag({ name, value })),
	resetVariations: () => (dispatch) => dispatch(resetVariations()),
	resetFeatureFlags: () => (dispatch) => dispatch(resetFeatureFlags()),
}

export const actions = actionsBuilder.exportActions()
