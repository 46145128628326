import { GROUP_COVERAGE_TYPES } from "./state"
import ConfigService from "../../services/config"

export const getGroupCoveragesByType = (product, type, selected) => {
	return (
		product.groupCoverages.filter(
			(groupCoverage) => groupCoverage.type === type && (!selected || groupCoverage.selected) && !groupCoverage.hidden
		) || []
	)
}

export const hasGroupCoveragesByType = (product, type, selected) => {
	return getGroupCoveragesByType(product, type, selected).length > 0
}

export const getNumSelectedGroupCoverageItems = (groupCoverage) => {
	switch (groupCoverage.type) {
		case GROUP_COVERAGE_TYPES.PICK5: {
			return groupCoverage.optionalCoverages.reduce((sum, coverageItem) => sum + coverageItem.quantity, 0)
		}
		case GROUP_COVERAGE_TYPES.PICK10: {
			return groupCoverage.optionalCoverages.reduce((sum, coverageItem) => sum + (coverageItem.quantity > 0 ? 1 : 0), 0)
		}
		default: {
			return 0
		}
	}
}

export const isGroupCoverageComplete = (groupCoverage) => {
	switch (groupCoverage.type) {
		case GROUP_COVERAGE_TYPES.PICK5: {
			return !groupCoverage.selected || 5 <= getNumSelectedGroupCoverageItems(groupCoverage)
		}
		case GROUP_COVERAGE_TYPES.PICK10: {
			return 10 <= getNumSelectedGroupCoverageItems(groupCoverage)
		}
		default: {
			return true
		}
	}
}

export const getSelectedOptionalCoverages = (product) => {
	return product.optionalCoverages.filter((coverageItem) => coverageItem.quantity > 0 && !coverageItem.hidden) || []
}

export const hasSelectedAdditionalCoverages = (product) => {
	const hasAdditionalGroupCoverages = product.groupCoverages.some((groupCoverage) => {
		return (
			(groupCoverage.type === GROUP_COVERAGE_TYPES.PICK5 || groupCoverage.type === GROUP_COVERAGE_TYPES.BUNDLE) &&
			groupCoverage.selected &&
			!groupCoverage.hidden
		)
	})

	const hasAdditionalOptionalCoverages = getSelectedOptionalCoverages(product).length > 0

	if (hasAdditionalGroupCoverages || hasAdditionalOptionalCoverages) {
		return true
	}

	return false
}

export const getCoverageDependencyRules = (product) => {
	const coverageItemDependencies = ConfigService.config.renewals.coverageItemDependencies

	// Group together all coverage IDs that have the same description
	const coverageMap = new Map()
	const descriptionCoverageIDs = new Map()

	const addDescriptionCoverageID = (coverageItem) => {
		coverageMap.set(coverageItem.coverageID, coverageItem)

		if (descriptionCoverageIDs.has(coverageItem.name?.trim())) {
			descriptionCoverageIDs.get(coverageItem.name?.trim()).add(coverageItem.coverageID)
		} else {
			descriptionCoverageIDs.set(coverageItem.name?.trim(), new Set([coverageItem.coverageID]))
		}
	}

	product.includedCoverages.forEach(addDescriptionCoverageID)
	product.optionalCoverages.forEach(addDescriptionCoverageID)
	product.groupCoverages.forEach((groupCoverage) => {
		groupCoverage.optionalCoverages.forEach(addDescriptionCoverageID)
	})

	// Create a new dependency map from the base map to include other coverages that match descriptions
	const expandedCoverageItemDependencies = new Map()

	for (const coverageID in coverageItemDependencies) {
		// Get this coverage item if it exists, and then get the other coverages that match the description
		if (coverageMap.has(coverageID) && !expandedCoverageItemDependencies.has(coverageID)) {
			// Expand the dependencies array to include other coverage IDs that match the description
			const dependencyCoverageIDs = Array.from(
				coverageItemDependencies[coverageID].reduce((coverageIDs, coverageID) => {
					if (coverageMap.has(coverageID)) {
						const coverageItem = coverageMap.get(coverageID)
						const otherCoverageIDs = descriptionCoverageIDs.get(coverageItem.name?.trim())

						otherCoverageIDs.forEach((otherCoverageID) => coverageIDs.add(otherCoverageID))
					}

					return coverageIDs
				}, new Set())
			).sort()

			// Find other coverages that match this one since they should have the same dependency
			const coverageItem = coverageMap.get(coverageID)
			const coverageIDs = descriptionCoverageIDs.get(coverageItem.name?.trim())

			// Add this dependency rule for all matching coverages
			coverageIDs.forEach((coverageID) => expandedCoverageItemDependencies.set(coverageID, dependencyCoverageIDs))
		}
	}

	return expandedCoverageItemDependencies
}

export const checkCoverageRules = (product) => {
	const coveragesInPickGroup = new Set()

	product.groupCoverages.forEach((groupCoverage) => {
		// eslint-disable-next-line default-case
		switch (groupCoverage.type) {
			case GROUP_COVERAGE_TYPES.FLEXCOMBO: {
				groupCoverage.optionalCoverages.forEach((coverageItem) => {
					coveragesInPickGroup.add(coverageItem.description?.trim())
				})
				break
			}
			case GROUP_COVERAGE_TYPES.BUNDLE: {
				groupCoverage.optionalCoverages.forEach((coverageItem) => {
					if (groupCoverage.selected) {
						coverageItem.quantity = 1
						coveragesInPickGroup.add(coverageItem.description?.trim())
					} else {
						coverageItem.quantity = 0
					}
				})
				break
			}
			case GROUP_COVERAGE_TYPES.PICK5: {
				groupCoverage.optionalCoverages.forEach((coverageItem) => {
					if (groupCoverage.selected) {
						coveragesInPickGroup.add(coverageItem.name?.trim())
					} else if (!groupCoverage.selected) {
						coverageItem.quantity = 0
					}
				})
				break
			}
			case GROUP_COVERAGE_TYPES.PICK10: {
				groupCoverage.optionalCoverages.forEach((coverageItem) => {
					coveragesInPickGroup.add(coverageItem.name?.trim())
				})
				break
			}
		}
	})

	product.optionalCoverages.forEach((coverageItem) => {
		coverageItem.hidden = coverageItem.isDNP

		if (coveragesInPickGroup.has(coverageItem.name?.trim())) {
			coverageItem.quantity = 0
			coverageItem.hidden = true
		}
	})

	// Now that all other quantities have been fixed, checked for dependency rules
	const coverageMaxQuantity = new Map()

	product.includedCoverages.forEach((coverageItem) => {
		coverageMaxQuantity.set(coverageItem.coverageID, 1)
	})

	product.groupCoverages.forEach((groupCoverage) => {
		groupCoverage.optionalCoverages.forEach((coverageItem) => {
			coverageMaxQuantity.set(
				coverageItem.coverageID,
				Math.max(coverageMaxQuantity.get(coverageItem.coverageID) || 0, coverageItem.quantity)
			)
		})
	})

	product.optionalCoverages.forEach((coverageItem) => {
		coverageMaxQuantity.set(
			coverageItem.coverageID,
			Math.max(coverageMaxQuantity.get(coverageItem.coverageID) || 0, coverageItem.quantity)
		)
	})

	const coverageItemDependencies = getCoverageDependencyRules(product)

	product.optionalCoverages.forEach((coverageItem) => {
		coverageItem.disabled = false

		if (coverageItemDependencies.has(coverageItem.coverageID)) {
			coverageItem.disabled = coverageItemDependencies.get(coverageItem.coverageID).every((qualifyingCoverageID) => {
				return !coverageMaxQuantity.has(qualifyingCoverageID) || coverageMaxQuantity.get(qualifyingCoverageID) === 0
			})

			if (coverageItem.disabled) {
				coverageItem.quantity = 0
			}
		}
	})
}

export const setInitialHiddenCoverages = (product) => {
	// Find which coverages need to be hidden from the page
	const regexDNP = /\bDNP\b/i
	const checkDNP = (item) => {
		item.isDNP = regexDNP.test(item.name)
		item.hidden = item.isDNP
	}
	const checkDNPForGroupCoverage = (groupCoverage) => {
		groupCoverage.isDNP = regexDNP.test(groupCoverage.description)
		groupCoverage.hidden = groupCoverage.isDNP
	}

	product.includedCoverages.forEach(checkDNP)

	product.groupCoverages.forEach((groupCoverage) => {
		checkDNPForGroupCoverage(groupCoverage)

		groupCoverage.optionalCoverages.forEach(checkDNP)
	})

	product.optionalCoverages.forEach(checkDNP)
}

export const moveOptionalQuantitiesIntoGroups = (product) => {
	// For pick 10 / pick 5, copy over the optional coverages into the group coverage
	product.optionalCoverages.forEach((coverageItem) => {
		if (coverageItem.quantity > 0) {
			product.groupCoverages.forEach((groupCoverage) => {
				if (groupCoverage.type === GROUP_COVERAGE_TYPES.PICK10) {
					groupCoverage.optionalCoverages.some((groupCoverageItem) => {
						if (groupCoverageItem.name?.trim() === coverageItem.name?.trim()) {
							groupCoverageItem.quantity = 1
							coverageItem.quantity = 0
							return true
						}
						return false
					})
				} else if (groupCoverage.type === GROUP_COVERAGE_TYPES.PICK5 && groupCoverage.selected) {
					groupCoverage.optionalCoverages.some((groupCoverageItem) => {
						if (groupCoverageItem.name?.trim() === coverageItem.name?.trim()) {
							groupCoverageItem.quantity += coverageItem.quantity
							coverageItem.quantity = 0
							return true
						}
						return false
					})
				}
			})
		}
	})
}

export const copyProductSelections = (oldProduct, newProduct) => {
	const selections = {
		groupCoverages: new Map(),
		optionalCoverages: new Map(),
	}

	oldProduct.groupCoverages.forEach((groupCoverage) => {
		const groupSelections = new Map()

		groupCoverage.optionalCoverages.forEach((coverageItem) => {
			groupSelections.set(coverageItem.coverageID, coverageItem.quantity)
		})

		selections.groupCoverages.set(groupCoverage.coverageID, {
			selected: groupCoverage.selected,
			optionalCoverages: groupSelections,
		})
	})

	oldProduct.optionalCoverages.forEach((coverageItem) => {
		selections.optionalCoverages.set(coverageItem.coverageID, coverageItem.quantity)
	})

	newProduct.groupCoverages.forEach((groupCoverage) => {
		const existing = selections.groupCoverages.get(groupCoverage.coverageID)

		if (existing) {
			groupCoverage.selected = existing.selected
			groupCoverage.optionalCoverages.forEach((coverageItem) => {
				if (existing.optionalCoverages.has(coverageItem.coverageID)) {
					coverageItem.quantity = existing.optionalCoverages.get(coverageItem.coverageID)
				}
			})
		}
	})

	newProduct.optionalCoverages.forEach((coverageItem) => {
		if (selections.optionalCoverages.has(coverageItem.coverageID)) {
			coverageItem.quantity = selections.optionalCoverages.get(coverageItem.coverageID)
		}
	})
}

export const canPriceProduct = (product) => {
	return !product.groupCoverages.some((groupCoverage) => !isGroupCoverageComplete(groupCoverage))
}

export const createPricingRequest = (product) => {
	// Create a copy of the product
	const request = JSON.parse(JSON.stringify(product))

	// For pick 10 group coverages, move excess of 10 into the optional coverage items
	// For pick 5 group coverages, move excess of 5 into the optional coverage items

	const extraForOptionalCoverages = new Map()

	request.includedCoverages = request.includedCoverages?.map((coverageItem) => ({
		coverageID: coverageItem.coverageID,
		name: coverageItem.name,
		description: coverageItem.description,
		quantity: coverageItem.quantity,
		price: coverageItem.price,
	}))

	request.groupCoverages = request.groupCoverages?.map((groupCoverage) => {
		if (
			(groupCoverage.selected && groupCoverage.type === GROUP_COVERAGE_TYPES.PICK5) ||
			groupCoverage.type === GROUP_COVERAGE_TYPES.PICK10
		) {
			const limit = groupCoverage.type === GROUP_COVERAGE_TYPES.PICK5 ? 5 : 10
			let current = 0

			groupCoverage.optionalCoverages = groupCoverage?.optionalCoverages.map((coverageItem) => {
				if (current + coverageItem.quantity > limit) {
					// Move to optional coverages
					const excess = current + coverageItem.quantity - limit
					current = limit

					coverageItem.quantity -= excess

					extraForOptionalCoverages.set(coverageItem.name?.trim(), excess)
				} else {
					current += coverageItem.quantity
				}

				return {
					coverageID: coverageItem.coverageID,
					name: coverageItem.name,
					description: coverageItem.description,
					quantity: coverageItem.quantity,
					price: coverageItem.price,
				}
			})
		} else {
			groupCoverage.optionalCoverages = groupCoverage?.optionalCoverages.map((coverageItem) => {
				return {
					coverageID: coverageItem.coverageID,
					name: coverageItem.name,
					description: coverageItem.description,
					quantity: coverageItem.quantity,
					price: coverageItem.price,
				}
			})
		}

		return {
			coverageID: groupCoverage.coverageID,
			description: groupCoverage.description,
			type: groupCoverage.type,
			selected: groupCoverage.selected,
			optionalCoverages: groupCoverage.optionalCoverages,
			price: groupCoverage.price,
		}
	})

	request.optionalCoverages = request.optionalCoverages?.map((coverageItem) => {
		if (extraForOptionalCoverages.has(coverageItem.name?.trim())) {
			coverageItem.quantity += extraForOptionalCoverages.get(coverageItem.name?.trim())
		}

		return {
			coverageID: coverageItem.coverageID,
			name: coverageItem.name,
			description: coverageItem.description,
			quantity: coverageItem.quantity,
			price: coverageItem.price,
		}
	})

	return request
}

export const sortCoveragesByDescription = (productInfo) => {
	const coverageItemDependencies = ConfigService.config.renewals.coverageItemDependencies

	const orderByDescriptionPredicate = (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())
	const orderByNamePredicate = (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
	const orderBySortOrder = (a, b) => {
		if (a.sortOrder && b.sortOrder) {
			return +a.sortOrder - +b.sortOrder
		} else if (a.sortOrder) {
			return -1
		} else if (b.sortOrder) {
			return 1
		} else {
			return orderByNamePredicate(a, b)
		}
	}

	const orderDependencies = (optionalCoverages) => {
		let sortIndex = 0
		const maxSorts = optionalCoverages.reduce((sum, coverage, index) => sum + index, 0)

		for (let i = 0; i < optionalCoverages.length && sortIndex < maxSorts; i++, sortIndex++) {
			const coverage = optionalCoverages[i]

			if (!coverageItemDependencies[coverage.coverageID]) {
				continue
			}

			const highestIndex = optionalCoverages.reduce((highestIndex, otherCoverage, index) => {
				if (coverageItemDependencies[coverage.coverageID].includes(otherCoverage.coverageID)) {
					return index
				} else {
					return highestIndex
				}
			}, -1)

			if (highestIndex <= i) {
				continue
			}

			optionalCoverages.splice(i, 1)
			optionalCoverages.splice(highestIndex, 0, coverage)

			// reset back at start since we moved a coverage
			i = -1
		}
	}

	productInfo.products.forEach((product) => {
		product.includedCoverages.sort(orderByNamePredicate)
		product.groupCoverages.sort(orderByDescriptionPredicate)
		product.optionalCoverages.sort(orderBySortOrder)
		orderDependencies(product.optionalCoverages)
		product.groupCoverages.forEach((groupCoverage) => {
			groupCoverage.optionalCoverages.sort(orderBySortOrder)
		})
	})

	productInfo.upgradeProducts.forEach((product) => {
		product.upgradeOptions.forEach((product) => {
			product.includedCoverages.sort(orderByNamePredicate)
			product.groupCoverages.sort(orderByDescriptionPredicate)
			product.optionalCoverages.sort(orderBySortOrder)
			orderDependencies(product.optionalCoverages)
			product.groupCoverages.forEach((groupCoverage) => {
				groupCoverage.optionalCoverages.sort(orderBySortOrder)
			})
		})
	})
}

export const getTSFList = (products) => products.map((product) => ({ fee: product.serviceFee, productVersionID: product.productVersionID }))
