import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import ConfigService from "../services/config"

export default class SentryLibrary {
	static initialize() {
		Sentry.init({
			dsn: "https://e4bc31e160e24496984779188107c975@o525107.ingest.sentry.io/6239497",
			integrations: [new Integrations.BrowserTracing()],
			tracesSampleRate: 1.0,
			environment: ConfigService.config.env,
			release: process.env.REACT_APP_CI_COMMIT_SHORT_SHA,
		})
	}
}
