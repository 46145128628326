export const PAYMENT_FREQUENCY = {
	MONTH: "MONTHLY",
	ANNUAL: "ANNUAL",
}

export const GROUP_COVERAGE_TYPES = {
	PICK5: "PICK5",
	PICK10: "PICK10",
	FLEXCOMBO: "FLEX_COMBO",
	BUNDLE: "BUNDLE",
}

export default {
	contract: null,
	isRequestCurrentContract(state) {
		return (
			state.user.isLoggedIn &&
			state.contract.current &&
			state.renewals.contract &&
			state.contract.current.contractID === state.renewals.contract.contractID
		)
	},

	productInfoLoadingPromise: null,
	productInfoError: false,
	products: [],
	selectedProduct: null,
	originalProduct: null,
	originalSalesChannel: null,

	isEZRenewalFlow: null,
	pricing: null,
	pricingLoadingPromise: null,
	pricingError: false,

	eFulfillmentAccessible: false,
	eFulfillmentEnabled: false,

	defaultBillingAddress: null,

	paymentInfo: null,

	confirmationDetails: null,
	upgradeProducts: [],
	tradeServiceFees: [],
	isSelectedProductIsUpgradePlan: false,
	isUpdatingProductOptions: false,
	fetchingPriceForNonSelectedPlanPromise: null,
	pricingErrorForNonSelectedPlan: null,
	planComparatorDetails: null,
	promoCode: null,
	migrationAlert: null,
}
