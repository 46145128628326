import ContactService from "../../services/contact"
import PaymentService from "../../services/payment"
import RenewalsService from "../../services/renewals"
import ZestyService from "../../services/zesty"
import { makeCancelable } from "../../utils"
import { ContractActions } from "../contract/actions"
import { ActionsBuilder } from "../utils"
import {
	checkCoverageRules,
	createPricingRequest,
	setInitialHiddenCoverages,
	moveOptionalQuantitiesIntoGroups,
	canPriceProduct,
	copyProductSelections,
	sortCoveragesByDescription,
	getTSFList,
} from "./helpers"
import namespace from "./namespace"
import contractNamespace from "../contract/namespace"
import * as analytics from "../../analytics/renewals.analytics"
import { SetUtagData } from "../../analytics/common.analytics"
import { AlertActions } from "../alert/actions"

const actionsBuilder = new ActionsBuilder(namespace)

export const setContract = actionsBuilder.createAction("setContract", (state, contract) => {
	state[namespace].contract = contract
})

export const updateContractDetails = actionsBuilder.createAction("updateContractDetails", (state, newContract) => {
	const contract = state[contractNamespace].current
	const mergedContract = { ...newContract, ...contract }

	state[contractNamespace].current = mergedContract
	state[contractNamespace].list = state[contractNamespace].list.map((c) =>
		c.contractID === mergedContract.contractID ? { ...mergedContract, ...c } : null
	)
	state[namespace].contract = mergedContract
})

export const setEZRenewalFlow = actionsBuilder.createAction("setEZRenewalFlow", (state, isEZRenewalFlow) => {
	state[namespace].isEZRenewalFlow = isEZRenewalFlow
})

export const resetState = actionsBuilder.createAction("resetState", (state) => {
	// contract lookup
	//state[namespace].contract = null

	// coverages page
	if (state[namespace].productInfoLoadingPromise) {
		state[namespace].productInfoLoadingPromise.cancel()
	}
	if (state[namespace].pricingLoadingPromise) {
		state[namespace].pricingLoadingPromise.cancel()
	}

	state[namespace].productInfoError = false
	state[namespace].productInfoLoadingPromise = null
	state[namespace].products = []
	state[namespace].selectedProduct = null
	state[namespace].originalProduct = null
	state[namespace].originalSalesChannel = null

	state[namespace].pricing = null
	state[namespace].pricingError = false
	state[namespace].pricingLoadingPromise = null

	state[namespace].eFulfillmentAccessible = false
	state[namespace].eFulfillmentEnabled = false

	state[namespace].defaultBillingAddress = null

	state[namespace].paymentInfo = null
	state[namespace].isEZRenewalFlow = false

	state[namespace].confirmationDetails = null

	state[namespace].upgradeProducts = []
	state[namespace].tradeServiceFees = []
	state[namespace].isSelectedProductIsUpgradePlan = false
	state[namespace].isUpdatingProductOptions = false
	state[namespace].fetchingPriceForNonSelectedPlanPromise = null
	state[namespace].promoCode = null
})

export const setProductInfoLoading = actionsBuilder.createAction("setProductInfoLoading", (state, promise) => {
	state[namespace].productInfoError = false
	state[namespace].productInfoLoadingPromise = promise
	state[namespace].products = []
	state[namespace].selectedProduct = null
	state[namespace].originalProduct = null
	state[namespace].originalSalesChannel = null

	state[namespace].pricing = null
	state[namespace].pricingError = false
	state[namespace].pricingLoadingPromise = null
	state[namespace].migrationAlert = null
})

export const setProductInfo = actionsBuilder.createAction("setProductInfo", (state, productInfo) => {
	if (productInfo) {
		state[namespace].products = productInfo.products
		state[namespace].selectedProduct = productInfo.selectedProduct
		state[namespace].originalProduct = JSON.parse(JSON.stringify(productInfo.selectedProduct))
		state[namespace].pricing = productInfo.pricing
		state[namespace].originalSalesChannel = productInfo.originalSalesChannel
		state[namespace].upgradeProducts = productInfo?.upgradeProducts?.filter(
			(upgradeProduct) => upgradeProduct?.upgradeOptions[0]?.hasPrices
		)
		state[namespace].tradeServiceFees = getTSFList(productInfo.products)
		state[namespace].migrationAlert = productInfo.migrationAlert
	}

	state[namespace].productInfoError = !productInfo
	state[namespace].productInfoLoadingPromise = null
	state[namespace].isSelectedProductIsUpgradePlan = false
})

export const setSelectedProduct = actionsBuilder.createAction(
	"setSelectedProduct",
	(state, { productVersionID, isUpgradePlan, shouldSetPricing }) => {
		// Copy over selections from old product to new product
		const oldProduct = state[namespace].selectedProduct
		let tradeServiceFees = []
		let newProduct = undefined
		const isSelectedProductIsUpgradePlan = isUpgradePlan ?? state[namespace].isSelectedProductIsUpgradePlan

		const setNewProduct = (products) => {
			newProduct = products.find((product) => product.productVersionID === productVersionID)

			if (newProduct) {
				tradeServiceFees = getTSFList(products)
			}

			return Boolean(newProduct)
		}

		if (isSelectedProductIsUpgradePlan) {
			state[namespace].upgradeProducts.some((product) => setNewProduct(product.upgradeOptions))
		} else {
			setNewProduct(state[namespace].products)
		}

		const contractID = state[namespace].contract.contractID

		if (newProduct === undefined) {
			analytics.LogProductNotDefined(contractID, "setSelectedProduct")
		}

		copyProductSelections(oldProduct, newProduct)

		checkCoverageRules(newProduct)

		state[namespace].selectedProduct = newProduct
		state[namespace].tradeServiceFees = tradeServiceFees
		state[namespace].isSelectedProductIsUpgradePlan = isSelectedProductIsUpgradePlan
		state[namespace].pricingErrorForNonSelectedPlan = null
	}
)

export const updateProductOptions = actionsBuilder.createAction("updateProductOptions", (state) => {
	const contractID = state[namespace].contract.contractID

	if (state[namespace].selectedProduct === undefined) {
		analytics.LogProductNotDefined(contractID, "updateProductOptions")
	}

	checkCoverageRules(state[namespace].selectedProduct)

	state[namespace].products = state[namespace].products.slice()
	state[namespace].isUpdatingProductOptions = true
})

const updatePricingSelections = (state, pricingLoadingPromise = null) => {
	const product = state[namespace].selectedProduct
	const contractID = state[namespace].contract.contractID

	if (product === undefined) {
		analytics.LogProductNotDefined(contractID, "updatePricingSelections")
	}
	const pricingRequest = createPricingRequest(product)

	pricingRequest.hasPrices = false

	// Make sure only hidden things are for DNP since all listed coverages should be shown if not DNP
	pricingRequest.optionalCoverages.forEach((coverageItem) => {
		coverageItem.hidden = coverageItem.isDNP
	})

	state[namespace].pricing = pricingRequest
	state[namespace].pricingError = false
	state[namespace].pricingLoadingPromise = pricingLoadingPromise
}

export const removePricing = actionsBuilder.createAction("removePricing", (state) => updatePricingSelections(state))

export const setPricingLoading = actionsBuilder.createAction("setPricingLoading", (state, promise) =>
	updatePricingSelections(state, promise)
)

export const setPricing = actionsBuilder.createAction("setPricing", (state, pricing) => {
	if (pricing) {
		const updatePlanWithPrice = (plan) => {
			if (plan.productVersionID === pricing.productVersionID) {
				plan.price = pricing.price
				plan.priceWithoutPromotions = pricing.priceWithoutPromotions
				plan.tax = pricing.tax
				plan.total = pricing.total
				plan.promotions = pricing.promotions

				plan.groupCoverages.forEach((g) => {
					const updatedPrice = pricing.groupCoverages.find((g2) => g.coverageID === g2.coverageID)?.price

					if (updatedPrice) {
						g.price = updatedPrice
					}
				})
			}

			return plan
		}
		// Make sure only hidden things are for DNP since all listed coverages should be shown if not DNP
		pricing.optionalCoverages.forEach((coverageItem) => {
			coverageItem.hidden = coverageItem.isDNP
		})

		state[namespace].pricing = pricing

		if (!state[namespace].isSelectedProductIsUpgradePlan) {
			state[namespace].products = state[namespace].products.map((plan) => updatePlanWithPrice(plan))
		} else {
			state[namespace].upgradeProducts = state[namespace].upgradeProducts.map((plans) => {
				plans.upgradeOptions = plans.upgradeOptions.map((plan) => updatePlanWithPrice(plan))

				return plans
			})
		}
	}

	state[namespace].pricingError = !pricing
	state[namespace].pricingLoadingPromise = null
	state[namespace].isUpdatingProductOptions = false
})

export const setPricingForNonSelectedPlansStart = actionsBuilder.createAction("setPricingForNonSelectedPlansStart", (state, pricing) => {
	state[namespace].fetchingPriceForNonSelectedPlanPromise = pricing
	state[namespace].pricingErrorForNonSelectedPlan = null
})

export const setPricingForNonSelectedPlans = actionsBuilder.createAction(
	"setPricingForNonSelectedPlans",
	(state, { product, productVersionID }) => {
		if (product) {
			const updatePlanWithPrice = (plan) => {
				if (plan.productVersionID === product.productVersionID) {
					plan.price = product.price
					plan.tax = product.tax
					plan.total = product.total
					plan.priceWithoutPromotions = product.priceWithoutPromotions
					plan.promotions = product.promotions
				}

				return plan
			}

			if (state[namespace].isSelectedProductIsUpgradePlan) {
				state[namespace].products = state[namespace].products.map((plan) => updatePlanWithPrice(plan))
			} else {
				state[namespace].upgradeProducts = state[namespace].upgradeProducts.map((plans) => {
					plans.upgradeOptions = plans.upgradeOptions.map((plan) => updatePlanWithPrice(plan))

					return plans
				})
			}
		}

		state[namespace].fetchingPriceForNonSelectedPlanPromise = null
		state[namespace].pricingErrorForNonSelectedPlan = productVersionID ?? null
	}
)

export const setEfulfillmentDetails = actionsBuilder.createAction(
	"setEfulfillmentDetails",
	(state, { eFulfillmentAccessible, eFulfillmentEnabled }) => {
		state[namespace].eFulfillmentAccessible = eFulfillmentAccessible
		state[namespace].eFulfillmentEnabled = eFulfillmentEnabled
	}
)

export const setDefaultBillingAddress = actionsBuilder.createAction("setDefaultBillingAddress", (state, defaultBillingAddress) => {
	state[namespace].defaultBillingAddress = defaultBillingAddress
})

export const setPaymentInfo = actionsBuilder.createAction("setPaymentInfo", (state, paymentInfo) => {
	state[namespace].paymentInfo = paymentInfo
})

export const setConfirmationDetails = actionsBuilder.createAction("setConfirmationDetails", (state, confirmationDetails) => {
	state[namespace].confirmationDetails = confirmationDetails
})

export const setPlanComparatorDetails = actionsBuilder.createAction("setPlanComparatorDetails", (state, planComparatorDetails) => {
	state[namespace].planComparatorDetails = planComparatorDetails
})

export const setPromoCode = actionsBuilder.createAction("setPromoCode", (state, promoCode) => {
	state[namespace].promoCode = promoCode
})

export const setPromoCodeOnSelectedProduct = actionsBuilder.createAction("setPromoCodeOnSelectedProduct", (state, promoCode) => {
	state[namespace].selectedProduct.promotions = promoCode?.length ? [{ promoCode: promoCode }] : [] //support only one promo at a time
})

export const setQuoteLoading = actionsBuilder.createAction("setQuoteLoading", (state, loading) => {
	state[namespace].pricingLoadingPromise = loading
})

const handleSetContract = (contract) => async (dispatch, getState) => {
	const state = getState()

	dispatch(setContract(contract))

	// if user is logged in, and if the contract is on their profile, then switch them to this contract
	if (state.user.isLoggedIn) {
		state.contract.list.some((userContract) => {
			if (userContract.contractID === contract.contractID) {
				if (state.contract.current?.contractID !== contract.contractID) {
					// change current contract to this one
					dispatch(ContractActions.setCurrentContract(contract.contractID))
				}
				return true
			}
			return false
		})
	}

	let utagData = { contract_id: contract.contractID }

	if (contract.eligibilityInfo?.nextContractID) {
		utagData = { ...utagData, upgrade_contract_id: contract.eligibilityInfo.nextContractID }
	}

	SetUtagData(utagData)
}

const handleSetProductInfo =
	(productInfo, isLoggedIn = false) =>
	async (dispatch) => {
		sortCoveragesByDescription(productInfo)

		productInfo.selectedProduct = productInfo.products.find(
			(product) => product.productVersionID === productInfo.selectedProductVersionID
		)

		// Make a copy of the selected product for pricing
		productInfo.pricing = JSON.parse(JSON.stringify(productInfo.selectedProduct))
		setInitialHiddenCoverages(productInfo.pricing)

		productInfo.products.forEach((product) => {
			moveOptionalQuantitiesIntoGroups(product)
			setInitialHiddenCoverages(product)
			checkCoverageRules(product)
		})

		dispatch(setProductInfo(productInfo))
		if (isLoggedIn) dispatch(updateContractDetails(productInfo.contractDetails))
	}

const handleGetPricing = () => async (dispatch, getState) => {
	const state = getState()
	const product = state[namespace].selectedProduct
	const { contract } = state[namespace]

	if (state[namespace].pricingLoadingPromise) {
		state[namespace].pricingLoadingPromise.cancel()
	}

	if (!contract) {
		return
	}

	if (!canPriceProduct(product)) {
		dispatch(removePricing())
	} else {
		try {
			dispatch(AlertActions.resetAlerts())
			if (product === undefined) {
				analytics.LogProductNotDefined(contract.contractID, "handleGetPricing")
			}

			const pricingRequest = createPricingRequest(product)
			const cancelablePromise = makeCancelable(RenewalsService.repriceRenewal(pricingRequest, contract.contractID))
			dispatch(setPricingLoading(cancelablePromise))

			const pricing = await cancelablePromise.promise
			setInitialHiddenCoverages(pricing)
			dispatch(setPricing(pricing))
		} catch (e) {
			if (!e.canceled) {
				analytics.ServerError(e)
				dispatch(setPricing())
				if (e.response.data?.errors.length > 0) dispatch(AlertActions.createAlert(e.response.data.errors, "error"))
			}
		}
	}
}

const handleGetPricingForNonSelectedPlans =
	(promoUpdated = false) =>
	async (dispatch, getState) => {
		const state = getState()
		const { contract, isSelectedProductIsUpgradePlan, products, selectedProduct, upgradeProducts } = state[namespace]
		const upgradePlans = upgradeProducts.reduce((acc, current) => {
			acc = acc.concat(current.upgradeOptions)
			return acc
		}, [])

		let product = isSelectedProductIsUpgradePlan
			? products.find((plan) => plan.serviceFee === selectedProduct.serviceFee)
			: upgradePlans.find((plan) => plan.serviceFee === selectedProduct.serviceFee)

		if (state[namespace].fetchingPriceForNonSelectedPlanPromise) {
			state[namespace].fetchingPriceForNonSelectedPlanPromise.cancel()
		}

		if (!contract || !product) {
			return
		}

		try {
			if (product === undefined) {
				analytics.LogProductNotDefined(contract.contractID, "handleGetPricingForNonSelectedPlans")
			}

			if (promoUpdated) {
				product = { ...product, promotions: state[namespace].selectedProduct.promotions }
			}

			if (!product.price || promoUpdated) {
				const pricingRequest = createPricingRequest(product)
				const cancelablePromise = makeCancelable(RenewalsService.repriceRenewal(pricingRequest, contract.contractID))
				dispatch(setPricingForNonSelectedPlansStart(cancelablePromise))

				const pricing = await cancelablePromise.promise

				dispatch(setPricingForNonSelectedPlans({ product: pricing }))
			}
		} catch (e) {
			if (promoUpdated) {
				product = { ...product, promotions: [] }
				dispatch(setPricingForNonSelectedPlans({}))
			} else if (!e.canceled) {
				analytics.ServerError(e)
				dispatch(setPricingForNonSelectedPlans({ productVersionID: product.productVersionID }))
			}
		}
	}

const handleFetchEfulfillment = (contract) => async (dispatch) => {
	const response = await ContactService.fetchEfulfillmentPreference(contract.contractID)

	dispatch(setEfulfillmentDetails(response))
}

const handleFetchDefaultBillingAddress = (contract) => async (dispatch) => {
	try {
		const defaultBillingAddress = await PaymentService.fetchDefaultBillingAddress(contract.contractID)
		dispatch(setDefaultBillingAddress(defaultBillingAddress))
	} catch (e) {
		// No need to throw an error if we don't have the default billing address
		console.error(e)
	}
}

const handleFetchPaymentInfo = (contract) => async (dispatch, getState) => {
	const state = getState()

	if (state.user.isLoggedIn) {
		state.contract.list.some((profileContract) => {
			if (profileContract.contractID === contract.contractID && profileContract.paymentInfo) {
				dispatch(setPaymentInfo(profileContract.paymentInfo))
				return true
			}
			return false
		})
	}
}

const handleFetchPlanComparatorDetails = (plans) => async (dispatch, getState) => {
	try {
		const response = await ZestyService.getPlanComparator(plans)
		dispatch(setPlanComparatorDetails(response))
	} catch (e) {
		console.log(e)
	}
}

export const RenewalsActions = {
	setContract: handleSetContract,

	setEfulfillmentDetails: setEfulfillmentDetails,

	resetState: resetState,

	handleFetchPlanComparatorDetails: handleFetchPlanComparatorDetails,

	lookupContract: (OTP, isEvergreen, promoCode) => async (dispatch, getState) => {
		dispatch(AlertActions.resetAlerts())

		const response = await RenewalsService.lookupContract(OTP, isEvergreen, promoCode)

		if (response.isLinkExpired) {
			return { isLinkExpired: true }
		}

		if (response.isContractAlreadyRenewed) {
			dispatch(handleSetContract(response.contract))
			return { isContractAlreadyRenewed: true }
		}

		if (response.isContractAlreadyChanged) {
			dispatch(handleSetContract(response.contract))
			return { isContractAlreadyChanged: true }
		}

		if (isEvergreen) {
			response.contract.eligibilityInfo = {
				nextContractID: response.nextContractID,
				expirationDate: response.contract.expirationDate,
			}
		} else {
			response.contract.eligibilityInfo = { expirationDate: response.contract.expirationDate }
		}

		dispatch(handleSetContract(response.contract))
		dispatch(setEZRenewalFlow(!isEvergreen))
		dispatch(handleSetProductInfo(response.productInfo))
		dispatch(AlertActions.createAlert(response.productInfo.warnings, "warning"))

		return { success: true }
	},

	fetchProductInfo: () => async (dispatch, getState) => {
		const state = getState()
		const { contract, promoCode } = state[namespace]

		if (state[namespace].productInfoLoadingPromise) {
			state[namespace].productInfoLoadingPromise.cancel()
		}

		if (!contract) {
			return
		}

		const cancelablePromise = makeCancelable(RenewalsService.fetchProductInformation(contract.contractID, promoCode))

		dispatch(AlertActions.resetAlerts())
		dispatch(setProductInfoLoading(cancelablePromise))

		try {
			const productInfo = await cancelablePromise.promise

			dispatch(handleSetProductInfo(productInfo, true))
			dispatch(AlertActions.createAlert(productInfo.warnings, "warning"))
		} catch (e) {
			if (!e.canceled) {
				console.error(e)
				analytics.ServerError(e)
				dispatch(setProductInfo())
			}
		}
	},

	setSelectedProduct: (productVersionID, isUpgradePlan) => async (dispatch) => {
		dispatch(setSelectedProduct({ productVersionID, isUpgradePlan }))

		// Reprice when changing TSF
		dispatch(handleGetPricing())
		dispatch(handleGetPricingForNonSelectedPlans())
	},

	updateProductOptions: (productVersionID) => async (dispatch) => {
		productVersionID && (await dispatch(setSelectedProduct({ productVersionID })))

		dispatch(updateProductOptions())

		// Reprice when changing options
		await dispatch(handleGetPricing())
	},

	setSelectedCoverages: () => async (dispatch, getState) => {
		const state = getState()
		const { contract } = state[namespace]

		if (!contract) {
			return
		}

		const product = state[namespace].selectedProduct
		if (!canPriceProduct(product)) {
			throw new Error("Cannot price current selections.")
		}

		dispatch(AlertActions.resetAlerts())
		await Promise.all([dispatch(handleFetchDefaultBillingAddress(contract)), dispatch(handleFetchPaymentInfo(contract))])

		if (product === undefined) {
			analytics.LogProductNotDefined(contract.contractID, "setSelectedCoverages")
		}

		const pricingRequest = createPricingRequest(product)
		try {
			const pricing = await RenewalsService.createQuote(pricingRequest, contract.contractID)

			setInitialHiddenCoverages(pricing)
			dispatch(setPricing(pricing))
		} catch (e) {
			dispatch(AlertActions.createAlert(e.response.data?.errors, "error"))
			setInitialHiddenCoverages()
			dispatch(setPricing())
		}
	},

	submitRenewal: (data) => async (dispatch, getState) => {
		const state = getState()
		const { contract } = state[namespace]

		if (!contract) {
			return
		}

		const { paymentMethodSaved, ...paymentMethod } = data.renewalPaymentInfo.paymentMethod

		const renewalPaymentInfo = {
			paymentMethod,
			paymentFrequency: data.renewalPaymentInfo.paymentFrequency,
			savePaymentMethod: data.renewalPaymentInfo.savePaymentMethod,
		}

		const submitRequest = {
			changeNote: data.changeNote,
			renewalPaymentInfo: renewalPaymentInfo,
		}

		await RenewalsService.submit(submitRequest, contract.contractID)

		dispatch(
			setConfirmationDetails({
				product: state[namespace].pricing,
				paymentMethod: data.renewalPaymentInfo.paymentMethod,
				paymentFrequency: data.renewalPaymentInfo.paymentFrequency,
			})
		)
	},

	submitUpgrade: (changeNote) => async (dispatch, getState) => {
		const state = getState()
		const { contract } = state[namespace]

		if (!contract) {
			return
		}

		const product = state[namespace].selectedProduct
		if (!canPriceProduct(product)) {
			throw new Error("Cannot price current selections.")
		}
		const renewalProduct = createPricingRequest(product)

		const { nextContractID, type, email, cardType, cardNumberLastFour, accountNumberLastFour } = await RenewalsService.submitUpgrade(
			{
				renewalProduct,
				changeNote,
			},
			contract.contractID
		)
		dispatch(
			setConfirmationDetails({
				product: state[namespace].pricing,
				nextContractID,
				paymentMethod: {
					type,
					email,
					cardType,
					cardNumberLastFour,
					accountNumberLastFour,
				},
			})
		)
	},

	getEfulfillment: () => async (dispatch, getState) => {
		const state = getState()
		const { contract } = state[namespace]

		if (!contract) {
			return
		}
		await dispatch(handleFetchEfulfillment(contract))
	},

	setPromoCode: (pc) => (dispatch) => {
		dispatch(setPromoCode(pc))
	},

	setPromoCodeOnSelectedProduct: (pc) => (dispatch) => {
		dispatch(setPromoCodeOnSelectedProduct(pc))
	},

	repriceAndCreateQuoteWithPromo: () => async (dispatch, getState) => {
		const state = getState()
		const product = state[namespace].selectedProduct
		const { contract } = state[namespace]

		const pricingRequest = createPricingRequest(product)
		try {
			dispatch(setQuoteLoading(true))
			await dispatch(handleGetPricingForNonSelectedPlans(true))
			const pricing = await RenewalsService.createQuote(pricingRequest, contract.contractID)
			setInitialHiddenCoverages(pricing)
			dispatch(setPricing(pricing))
			dispatch(setQuoteLoading(null))
		} catch (e) {
			dispatch(setPromoCodeOnSelectedProduct())
			dispatch(setQuoteLoading(null))
			return false
		}
		return true
	},
}

export const actions = actionsBuilder.exportActions()
