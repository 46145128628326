import { useAppContext } from "@ftdr/blueprint-components-react"
import React from "react"
import { connect } from "react-redux"
import { setPageTitle } from "../../utils"

export const PageRoute = ({ route, state }) => {
	let { meta, component: Component, ...rest } = route
	route = { ...rest }

	const {
		appSettings: { localizedText },
	} = useAppContext()

	React.useEffect(() => {
		if (meta?.title) {
			setPageTitle(localizedText(meta.title))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <Component />
}

const mapStateToProps = (state) => ({
	state: state,
})

export default connect(mapStateToProps)(PageRoute)
