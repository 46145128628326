import GenericService from "./generic"

const SERVICE_NAME = "coreProductZesty"

class CoreProductZestyService extends GenericService {
	constructor() {
		super(SERVICE_NAME)
	}

	_getJSON(contentPath, config = {}) {
		return this.axios.get(contentPath, { responseType: "json", ...config })
	}

	getPromoBoxRules(params) {
		return this._getJSON("/promo_box_rules.json", {
			params: params,
		}).then((res) => res)
	}
}

export default new CoreProductZestyService()
