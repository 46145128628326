import React, { useEffect, useState } from "react"
import { GlobalHeader, useAppContext, HsaLogoColor, Ahs2024LogomarkColor, Ahs2024LogoColor } from "@ftdr/blueprint-components-react"
import { UserActions } from "../../store/user/actions"
import { getUrlByType, isDesktop, isSiteAHS } from "../../utils"
import { HeaderID } from "../../services/homebase"
import { connect } from "react-redux"
import ConfigService from "../../services/config"
import { compose } from "redux"
import { withRouter } from "react-router-dom"
import { AppActions } from "../../store/app/actions"

function debounce(fn, ms) {
	let timer
	return (_) => {
		clearTimeout(timer)
		timer = setTimeout(function (_) {
			timer = null
			fn.apply(this, arguments)
		}, ms)
	}
}

export const Header = (props) => {
	const config = ConfigService?.config
	const { isLoggedIn, profile, headerContent, location, user } = props
	const [isMobile, setIsMobileView] = useState(window.innerWidth < 768)

	const {
		appSettings: { localizedText },
	} = useAppContext()

	React.useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setIsMobileView(window.innerWidth < 768)
		}, 100)

		window.addEventListener("resize", debouncedHandleResize)

		return () => {
			window.removeEventListener("resize", debouncedHandleResize)
		}
	}, [])

	const getNavLinks = (links) => {
		return links.map((link) => {
			const url = link.href.replace(/([/.?*+^$[\]\\(){}|-])/g, "\\$1")
			const regExp = new RegExp(`${url}`, "i")

			return {
				...link,
				active: regExp.test(location.pathname),
			}
		})
	}

	const handleLogout = () => {
		props.logout()
	}

	const clickHandler = {
		[HeaderID.logout]: handleLogout,
	}

	const getHeaderSettings = () => {
		const navMenu = headerContent
			.find((item) => item.menuText === "navMenu")
			.children.filter((item) => (isMobile ? true : !item.isMobile))
			.map((item, i) => {
				return {
					id: `${i}`,
					label: localizedText(item.menuText),
					href: ConfigService.config.site.myAccountDomain + getUrlByType(item.type, item.href, config.external),
					onClick: clickHandler[item.id],
				}
			})

		const userMenu = headerContent
			.find((item) => item.menuText === "userMenu")
			.children.filter((item) => (item.id === "contactInfo" ? user.hasCompleteProfile() : true))
			.map((item) => ({
				label: item.menuText,
				onClick() {
					if (item.id === "contactInfo") {
						window.location.assign(
							ConfigService.config.site.myAccountDomain + getUrlByType(item.type, item.href, config.external)
						)
					} else if (item.id === "signOut") {
						props.logout()
					}
				},
			}))

		return {
			ctaButtons: [
				{
					label: "Create Account",
					variant: "outlined",
					size: "small",
					href: ConfigService.config.site.myAccountDomain + `/login`,
					placementOnMobile: "main-header",
					hideWhenSignedIn: true,
				},
				{
					label: "Login",
					variant: "ghost",
					size: "small",
					className: "underline",
					href: ConfigService.config.site.myAccountDomain + `/login`,
					placementOnMobile: "main-header",
					hideWhenSignedIn: true,
				},
			],
			utilityHeaderLinks: [],
			utilityHeaderIconLinks: [],
			signInLinkOptions: null,
			userMenuProps: {
				firstName: profile?.firstName,
				lastName: profile?.lastName,
				menuItems: userMenu,
			},
			navMenuItems: getNavLinks(navMenu),
		}
	}

	const variant = "light"
	const settings = headerContent ? getHeaderSettings() : null
	const [navMenuItems, setNavMenuItems] = useState()

	const getHeaderLogo = () => {
		if (isSiteAHS()) {
			return isDesktop() ? (
				<Ahs2024LogoColor data-testid="AhsLogoColor" height={50} />
			) : (
				<Ahs2024LogomarkColor data-testid="AhsLogomarkColor" height={50} />
			)
		} else {
			return <HsaLogoColor data-testid="HsaLogoColor" height={50} />
		}
	}

	useEffect(() => {
		if (settings) {
			!navMenuItems && setNavMenuItems(settings.navMenuItems)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings])

	useEffect(() => {
		if (settings) {
			const headerSettings = getHeaderSettings()
			setNavMenuItems(headerSettings.navMenuItems)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile])

	return (
		settings && (
			<div id="page-header" data-testid="PageHeader">
				<div className="header-wrapper my-0 mx-auto pt-0 md:pt-16">
					<GlobalHeader
						variant={variant}
						logoRenderer={() => (
							<a className="flex-1 md:flex-none mr-6 md:mr-0" href={ConfigService.config.site.myAccountDomain}>
								{getHeaderLogo()}
							</a>
						)}
						navMenuItems={isLoggedIn ? navMenuItems : []}
						ctaButtons={settings.ctaButtons}
						utilityHeaderLinks={settings.utilityHeaderLinks}
						utilityHeaderIconLinks={settings.utilityHeaderIconLinks}
						utilityHeaderLinksAlignment="center"
						isUserSignedIn={isLoggedIn}
						userMenuProps={settings.userMenuProps}
						sticky
						signInLinkOptions={settings.signInLinkOptions}
						constrainContentWidth={true}
						menuColor={isSiteAHS() ? "interactive" : "primary"}
					/>
				</div>
			</div>
		)
	)
}

const mapStateToProps = (state) => ({
	headerContent: state.zesty.header?.content,
	user: state.user,
	profile: state.user.profile,
	isLoggedIn: state.user.isLoggedIn,
})

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(UserActions.logout()),
	toggleMobileNavMenuOpen: (e) => {
		// do not change the URL hash when clicking on the menu button
		e.preventDefault()
		return dispatch(AppActions.toggleMobileNavMenuOpen())
	},
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header)
