import { ActionsBuilder } from "../utils"
import namespace from "./namespace"

const actionsBuilder = new ActionsBuilder(namespace)

export const AppActions = {
	showPageOverlay: actionsBuilder.createAction("showPageOverlay", (state) => {
		state[namespace].showPageOverlay = true
	}),

	hidePageOverlay: actionsBuilder.createAction("hidePageOverlay", (state) => {
		state[namespace].showPageOverlay = false
	}),

	togglePageOverlay: actionsBuilder.createAction("togglePageOverlay", (state, value) => {
		state[namespace].showPageOverlay = typeof value !== "undefined" ? !!value : !state[namespace].showPageOverlay
	}),

	toggleMobileNavMenuOpen: actionsBuilder.createAction("toggleMobileNavMenuOpen", (state) => {
		state[namespace].isMobileNavMenuOpen = !Boolean(state[namespace].isMobileNavMenuOpen)
	}),
}

export const actions = actionsBuilder.exportActions()
