import axios from "axios"
import AuthService from "./auth"
import ConfigService from "./config"
import { extend } from "../utils"
import querystring from "querystring"
import * as analytics from "../analytics/serviceError.analytics"

export class ServiceException {
	constructor(error) {
		this.message = error.message
		this.name = error.name || "ServiceException"

		if (error.isAxiosError) {
			this.isAxiosError = true
			this.config = error.config
			this.request = error.request
			this.response = error.response
			analytics.ServiceError(error)
		}
	}
}

export default class GenericService {
	ServiceException = ServiceException

	constructor(name) {
		this.name = name
		this.axios = this.createAxiosInstance()
	}

	/*
	 * Creates a new axios instance using provided config
	 * Adds a request interceptor to add the service config as a base request config
	 */
	createAxiosInstance(config) {
		const instance = axios.create({
			...config,
			paramsSerializer: (params) => querystring.stringify(params),
		})

		instance.interceptors.request.use((config) => {
			const serviceConfig = this.getServiceConfig()

			return extend({}, serviceConfig, config)
		})

		instance.interceptors.response.use((response) => response.data)

		// Set a common error handler that can be removed and replaced with another one by access token interceptors
		this._defaultAxiosErrorHandler = instance.interceptors.response.use(null, (error) => Promise.reject(this.handleError(error)))

		return instance
	}

	/*
	 * Adds interceptors for handling access token headers and refreshing access token on 401 errors
	 */
	useAccessTokenInAxios({ refreshTokenOnUnauthorized = true } = {}) {
		this.axios.interceptors.request.use(async (config) => {
			const userConfig = {}
			const { token, tokenType } = await this.getAccessToken()

			if (token) {
				userConfig.headers = {
					Authorization: `${tokenType} ${token}`,
				}
			}

			return extend(config, userConfig)
		})

		// Remove default error handler and use a new one here
		if (this._defaultAxiosErrorHandler) {
			this.axios.interceptors.response.eject(this._defaultAxiosErrorHandler)
			this._defaultAxiosErrorHandler = null
		}

		// Optionally add refresh token login when call responds with 401 error
		if (refreshTokenOnUnauthorized) {
			this.axios.interceptors.response.use(null, async (error) => {
				// Check if we got a 401 unauthorized error for the first time
				if (error.response && error.response.status === 401 && !error.config._retryAfterAuth) {
					// If we get a 401 in renewals, redirect back to MyAccount to handle login.
					window.location.replace(ConfigService.config.site.myAccountDomain + "/login")
				}

				// Since we are using an async function, it will return a promise
				// Therefore throwing an exception here is the same as a non-async function using Promise.reject() to return
				throw error
			})
		}

		// Add back the similar error handler from before so we can handle any uncaught errors whether it's from axios or the AuthService client methods
		this.axios.interceptors.response.use(null, (error) => Promise.reject(this.handleError(error)))
	}

	/*
	 * Gets the config for this service from the ConfigService
	 */
	getServiceConfig() {
		return Object.assign({}, ConfigService.config?.services?.["*"], ConfigService.config?.services?.[this.name])
	}

	/*
	 * Logs the error to the console and returns an exception
	 */
	handleError(e) {
		if (e.response) {
			console.error(`[Service Error: ${this.name}] Not OK response: ${e.response.status} ${e.response.statusText}`)
		} else if (e.request) {
			console.error(`[Service Error: ${this.name}] No response:`, e.request)
		} else {
			console.error(`[Service Error: ${this.name}] Uncaught exception: ${e.message}`)
		}

		return new ServiceException(e)
	}

	/*
	 * Returns a promise that resolves with the user's access token
	 */
	getAccessToken() {
		return AuthService.getAccessToken()
	}
}
