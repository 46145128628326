import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import ZestyService from "../../services/zesty"
import HomebaseService from "../../services/homebase"
import CoreProductZestyService from "../../services/coreProductZesty"

const actionsBuilder = new ActionsBuilder(namespace)

export const getZestyContentStart = actionsBuilder.createAction("getZestyContentStart", (state, contentKey) => {
	state[namespace][contentKey].isLoading = true
})

export const getZestyContentComplete = actionsBuilder.createAction("getZestyContentComplete", (state, { contentKey, content }) => {
	state[namespace][contentKey].content = content
	state[namespace][contentKey].isLoading = false
	state[namespace][contentKey].hasLoaded = true
})

const createZestyAction = (contentKey, fetchContent) => async (dispatch, getState) => {
	const state = getState()

	if (!(contentKey in state[namespace])) {
		return null
	}

	if (state[namespace][contentKey].hasLoaded) {
		return state[namespace][contentKey].content
	}

	if (state[namespace][contentKey].isLoading) {
		return null
	}

	dispatch(getZestyContentStart(contentKey))

	const content = await fetchContent()

	dispatch(getZestyContentComplete({ contentKey, content }))

	return content
}

export const ZestyActions = {
	getHeader: (siteName) => createZestyAction("header", () => HomebaseService.getHeader(siteName)),
	getFooter: (siteName) => createZestyAction("footer", () => HomebaseService.getFooter(siteName)),
	getRenewalConfig: () => createZestyAction("renewalConfig", () => ZestyService.getRenewalConfig()),
	getIsEligibleForPromoBox: (params) => createZestyAction("promoBoxConfig", () => CoreProductZestyService.getPromoBoxRules(params)),
}

export const actions = actionsBuilder.exportActions()
