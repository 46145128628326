export default {
	paymentInfoLoading: false,
	paymentInfoLoadingPromise: null,
	paymentInfoError: false,
	paymentInfo: null,
	defaultBillingAddress: null,
	defaultBillingAddressLoading: false,
	defaultBillingAddressError: false,
	defaultBillingAddressLoadingPromise: null,
}
