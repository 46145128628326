import { capitalizeWords } from "../utils"
import GenericService from "./generic"

const SERVICE_NAME = "renewals"

class RenewalsService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
	}

	setChatAgent(contractID, agent) {
		return this.axios.post(`/my-account-renewals/${contractID}/chat-agent`, agent)
	}

	async lookupContract(OTP, isEvergreen, promoCode) {
		let response
		const searchParams = promoCode ? "?pc=" + promoCode : ""
		try {
			if (isEvergreen) {
				response = await this.axios.get(`/my-account-renewals/upgrade-lookup/${OTP}${searchParams}`, {
					validateStatus: (status) => status === 200,
				})
			} else {
				response = await this.axios.get(`/my-account-renewals/lookup/${OTP}${searchParams}`, {
					validateStatus: (status) => status === 200,
				})
			}

			if (response.contract?.property) {
				response.contract.property.streetAddress = capitalizeWords(response.contract.property.streetAddress)
				response.contract.property.streetAddress2 = capitalizeWords(response.contract.property.streetAddress2)
				response.contract.property.city = capitalizeWords(response.contract.property.city)
			}

			return response
		} catch (e) {
			// Handle 204 response as an expired link
			if (e.isAxiosError && e.response.status === 204) {
				return { isLinkExpired: true }
			}

			throw e
		}
	}

	fetchProductInformation(contractID, promoCode) {
		const searchParams = promoCode ? "?pc=" + promoCode : ""

		return this.axios.get(`/my-account-renewals/${contractID}/renewal-product${searchParams}`)
	}

	repriceRenewal(pricingRequest, contractID) {
		return this.axios.post(`/my-account-renewals/${contractID}/reprice-product`, pricingRequest)
	}

	createQuote(pricingRequest, contractID) {
		return this.axios.post(`/my-account-renewals/${contractID}/create-quote`, pricingRequest)
	}

	submit(request, contractID) {
		return this.axios.post(`/my-account-renewals/${contractID}/submit`, request, {
			headers: {
				splitFlagMFE: "on",
			},
		})
	}

	submitUpgrade(request, contractID) {
		return this.axios.post(`/my-account-renewals/${contractID}/submit-upgrade`, request)
	}
}

export default new RenewalsService()
