import { IconWarningCircledFull, Message, Panel } from "@ftdr/blueprint-components-react"

const ErrorCard = ({ children, className, ...props }) => (
	<Panel border="0" shadow className={className} {...props}>
		<Message className="m-2" showStatusLabel={false} status="error" matchColor icon={<IconWarningCircledFull />}>
			{children}
		</Message>
	</Panel>
)

export default ErrorCard
