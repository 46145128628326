import { extend } from "../utils"

export class ActionsBuilder {
	constructor(namespace) {
		this.namespace = namespace
		this.actions = {}
	}

	createAction(type, handler) {
		type = this.namespace ? `${this.namespace}/${type}` : type

		this.actions[type] = handler

		return (payload) => ({ type, payload })
	}

	exportActions() {
		return this.actions
	}
}

function copyState(state) {
	state = Object.assign({}, state)

	for (const namespace in state) {
		state[namespace] = Object.assign({}, state[namespace])
	}

	return state
}

export const createReducer =
	({ initialState, actions }) =>
	(state, action) => {
		if (state === undefined) {
			state = copyState(initialState)
		}

		if (action.type === "RESET_STORE") {
			state = copyState(initialState)
		} else if (action.type === "OVERRIDE_ACTION") {
			actions[action.payload.action] = action.payload.handler
		} else if (action.type in actions) {
			// Create a copy of each namespace
			state = copyState(state)

			actions[action.type](state, action.payload)
		}

		return state
	}

export const combineReducers = (modules, initialState = {}) => {
	const config = {
		initialState: {},
		actions: {},
	}

	for (const key in modules) {
		const { initialState, actions, namespace } = modules[key]

		config.initialState[namespace] = initialState
		Object.assign(config.actions, actions)
	}
	config.initialState = extend(config.initialState, initialState)

	return config
}
