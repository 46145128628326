import { CustomUtagLink } from "./common.analytics"
import { getAxiosErrorMessage } from "../utils"

export const ServiceError = (axiosError) => {
	const path = axiosError?.request?.responseURL
	const statusCode = axiosError?.request?.status
	const errorMessage = getAxiosErrorMessage(axiosError)

	CustomUtagLink({
		event_category: "My Account Errors",
		event_action: `Endpoint: ${path}`,
		event_label: `${statusCode}: ${errorMessage}`,
	})
}
