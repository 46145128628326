import GenericService from "./generic"

const SERVICE_NAME = "homebase"

export const ExternalLinkType = {
	MY_ACCOUNT: "MY_ACCOUNT",
	PRO_CONNECT: "PRO_CONNECT",
	AHS: "MY_ACCOUNT",
	HOMEBASE: "HOMEBASE",
	HSA: "HSA",
}

export const FooterID = {
	openChat: "openChat",
	downloadContract: "downloadContract",
}

export const HeaderID = {
	logout: "logout",
}

class HomebaseService extends GenericService {
	constructor() {
		super(SERVICE_NAME)
	}

	getJSON(contentPath, config) {
		return this.axios.get(contentPath, {
			responseType: "json",
			...(config || {}),
		})
	}

	async getFooter(tenant) {
		return await this.getJSON("/footer.json", {
			params: {
				tenant,
			},
		}).then((response) => {
			return response.content
		})
	}

	async getHeader(tenant) {
		return await this.getJSON("/header.json", {
			params: {
				tenant,
			},
		}).then((response) => {
			const content = response.content
			content
				.find((item) => item.menuText === "navMenu")
				.children.push(
					...[
						{
							href: "/add-property",
							id: "",
							menuText: "DASHBOARD_NAV.LINK_ADD_PROPERTY",
							type: "MY_ACCOUNT",
							isMobile: true,
						},
						{
							href: "#",
							id: "logout",
							menuText: "DASHBOARD_NAV.MOBILE_ITEM_LOGOUT",
							type: "NONE",
							isMobile: true,
						},
					]
				)
			return content
		})
	}
}

export default new HomebaseService()
