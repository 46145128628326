import React from "react"

export default function ExtendedContext(props) {
	const { children, value, Context } = props

	return (
		<Context.Consumer>
			{(context) => {
				const extendedContext = {
					...context,
					...value,
				}

				return <Context.Provider value={extendedContext}>{children}</Context.Provider>
			}}
		</Context.Consumer>
	)
}
