class ContentItem {
	content = null
	isLoading = false
	hasLoaded = false
}

ContentItem.prototype[Symbol.toStringTag] = "ContentItem"

export default {
	header: new ContentItem(),
	footer: new ContentItem(),
	renewalConfig: new ContentItem(),
	promoBoxConfig: new ContentItem(),
}
