import React from "react"
import ErrorCard from "../components/ErrorCard"

class ErrorPage extends React.Component {
	render() {
		return (
			<div className="container md:mt-4" data-testid="ErrorPage">
				<ErrorCard data-testid="ErrorMessage">{this.props.children || <>There was an error loading the page.</>}</ErrorCard>
			</div>
		)
	}
}

export default ErrorPage
