import React from "react"
import { connect } from "react-redux"
import Page from "../../components/Page"
import ErrorPage from "../../pages/Error"
import AuthService from "../../services/auth"
import { ContractActions } from "../../store/contract/actions"
import { UserActions } from "../../store/user/actions"
import PageRoute from "./PageRoute"
import jwtDecode from "jwt-decode"
import moment from "moment"
import Cookies from "universal-cookie"
import PageLoader from "../../components/PageLoader"

export const UserPageRoute = ({ isLoggedIn, autoLoginUser, route }) => {
	const [startedLoggingIn, setStartedLoggingIn] = React.useState(false)
	const [errorLoggingIn, setErrorLoggingIn] = React.useState(false)
	const [doneLoggingIn, setDoneLoggingIn] = React.useState(false)

	React.useEffect(() => {
		if (!isLoggedIn && !startedLoggingIn) {
			setStartedLoggingIn(true)

			autoLoginUser()
				.then(() => setDoneLoggingIn(true))
				.catch((e) => {
					console.error(e)
					setErrorLoggingIn(true)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, startedLoggingIn])

	if (errorLoggingIn) {
		return (
			<Page>
				<ErrorPage />
			</Page>
		)
	} else if ((!isLoggedIn && !startedLoggingIn) || (startedLoggingIn && !doneLoggingIn)) {
		return <PageLoader />
	} else {
		return <PageRoute route={route} />
	}
}

const mapStateToProps = (state) => ({
	isLoggedIn: state.user.isLoggedIn,
})

const mapDispatchToProps = (dispatch) => ({
	autoLoginUser: async () => {
		const accessToken = AuthService.getAccessTokenFromCookie()
		if (accessToken) {
			//check expiration using JWT library
			if (moment.unix(jwtDecode(accessToken).exp).isAfter(moment.now())) {
				const isLoggedIn = await dispatch(UserActions.login())

				if (isLoggedIn) {
					const fetchedContracts = await dispatch(ContractActions.fetchContracts())

					if (!fetchedContracts) {
						throw new Error("Could not fetch contracts")
					}
				}
			} else {
				// Remove access token cookie
				const cookies = new Cookies()
				cookies.remove("accessToken")
			}
		}
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPageRoute)
