import { lazy } from "react"
import { withRouter } from "react-router-dom"

const routes = [
	{
		// USE FOR DEBUGGING FUSION AUTH LOGIN
		path: "/fusionauth",
		component: lazy(() => import("../../pages/FusionAuthLanding")),
	},
	{
		path: ["/http:", "/https:"],
		component: withRouter(({ location }) => {
			window.location.replace(location.pathname.substr(1))
			return null
		}),
	},
	{
		path: "/my-account-renewals-ui",
		component: withRouter(({ location }) => {
			window.location.replace(location.pathname.replace("/my-account-renewals-ui", ""))
			return null
		}),
	},
	{
		path: ["/upgrade-lookup/:OTP", "/lookup/:OTP", "/:currentStep", "/"],
		exact: true,
		component: lazy(() => import("../../pages/Renewals")),
		meta: {
			title: "PAGE_TITLE.RENEWALS",
		},
	},
	{
		path: "/",
		component: lazy(() => import("../../pages/NotFound")),
		meta: {
			title: "PAGE_TITLE.PAGE_NOT_FOUND",
		},
	},
]

export default routes
