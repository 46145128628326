import { CustomUtagLink } from "./common.analytics"
import { stringifyError, PAYMENT_METHODS } from "../utils"

export const FirstStepBtnClicked = (contractID, customerID) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: "Renewal Step One Continue Clicked",
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const SecondStepBtnClicked = (contractID, customerID) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: "Renewal Step Two Continue Clicked",
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const ThirdStepBtnClicked = (contractID, customerID) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: "Renewal Step Three Make Payment Clicked",
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const FulfillmentPreference = (contractID, customerID, eFulfillmentEnabled) => {
	const state = eFulfillmentEnabled ? "Checked" : "Unchecked"
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: `E-Fulfillment ${state} on Submit`,
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const StoredPaymentMethod = (contractID, customerID) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: "Stored Payment Method",
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const SuccessfulRenewal = (contractID, customerID, paymentType, isProductSwitch) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: isProductSwitch ? "Product Switch Successful" : "Successful Renewal",
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
		payment_type: `${paymentType}`,
	})
}

export const SetRenewalFrequency = (contractID, customerID, paymentType, isMonthlyFrequency) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: isMonthlyFrequency ? "Monthly Payment" : "Annual Payment",
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
		payment_type: `${paymentType}`,
	})
}

export const SetRenewalPaymentMethod = (contractID, customerID, paymentType) => {
	const LabelMap = {
		[PAYMENT_METHODS.BANK_ACCOUNT]: "ACH Renewal Payment",
		[PAYMENT_METHODS.CREDIT_CARD]: "CC Renewal Payment",
		[PAYMENT_METHODS.PAYPAL]: "Paypal Renewal Payment",
	}

	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: LabelMap[paymentType],
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
		payment_type: `${paymentType}`,
	})
}

export const ServerError = (error) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: stringifyError(error),
	})
}

export const TradeServiceFeeChanged = (contractID, customerID, oldFee, newFee) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: `Changed TSCF ${oldFee} to ${newFee}`,
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const LogProductNotDefined = (contractID, location) => {
	CustomUtagLink({
		event_category: "My Account Log", // New category to separate in GA away from normal MyAccount events, specifically for logs
		event_action: "Online Renewal",
		event_label: `Product not defined in ${location}`,
		contract_id: `${contractID}`,
	})
}

export const RenewalProductChanged = (contractID, customerID, oldPlan, newPlan) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: `Changed from ${oldPlan} to ${newPlan}`,
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const AlternateRenewalProductsShown = (contractID, customerID, selectedProductName, upgradeProducts) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: `Alternate products shown ${upgradeProducts?.join()} for ${selectedProductName}`,
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const NoUpgradeOptionsShown = (contractID, customerID, currentPlanName) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: `No Upgrade products shown for ${currentPlanName}`,
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const SampleContractLinkClicked = (contractID, customerID) => {
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: `Sample contract link clicked`,
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}

export const AddOnsSelected = (contractID, customerID, selectedAddons = [], removedAddons = []) => {
	let label = ""
	if (selectedAddons.length) {
		label = `Selected ${selectedAddons.join(", ")}`
	}
	if (removedAddons.length) {
		label += label.length ? " and " : ""
		label += `Deselected ${removedAddons.join(", ")}`
	}
	CustomUtagLink({
		event_category: "My Account",
		event_action: "Online Renewal",
		event_label: label,
		contract_id: `${contractID}`,
		customer_id: `${customerID}`,
	})
}
